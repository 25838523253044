<template>
  <div class="scrollx p40" >
    <input class="h36" v-model.lazy="search" placeholder="Search">
    <select v-model="category" class="ml12">
      <option selected value="">Select Category</option>
      <option v-for="c in categories" :key="c.id" :value="c.id">{{c.name}}</option>
    </select>
    <span class="ml20">{{filteredQuestions.length}} questions</span>
    <table class="ui celled table"> 
      <thead>
        <tr>
          <th>Id</th>
          <th>Category</th>
          <th>Question</th>
          <th>Image</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(q) in filteredQuestions" :key="q.id">
          <td>{{q.id}}</td>
          <td>{{(q.Category || {}).name}}</td>
          <td style="max-width: 250px;" v-html="q.Question"></td>
          <td 
            :style="{
              background: 'url(' + (q.Image || {}).url + ') center / contain no-repeat'
            }">
          </td>
          <td>
            <button @click="showModal(q)" class="fs14">Edit</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="p20 ui edit modal relative">
      <button class="absolute" style="top: 12px; right: 12px;" @click="hideModal">
        <i class="close icon"></i>
      </button>
      <h3 class="mb40">Edit Question</h3>
      <div
        v-if="activeImage" 
        :style="{
          background: 'url(' + activeImage + ') center / contain no-repeat',
          width: '100%',
          height: '100px',
          margin: 'auto'
        }">
      </div>
      <label>Question</label>
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <i class="bold icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <i class="italic icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <i class="underline icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <i class="paragraph icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.link() }"
            @click="commands.link();"
            >
            <i class="chain icon" />
          </button>

          <button
            class="menubar__button"
            @click="commands.undo"
          >
            <i class="undo icon" />
          </button>

          <button
            class="menubar__button"
            @click="commands.redo"
          >
            <i class="redo icon" />
          </button>
        </div>
      </editor-menu-bar>
      <editor-content style="margin: 20px 0px 40px 0px;" class="editor email-content content" :editor="editor" />

      <label>Explanation</label>
      <editor-menu-bar :editor="explanationEditor" v-slot="{ commands, isActive }">
        <div class="menubar">
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <i class="bold icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <i class="italic icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <i class="underline icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <i class="paragraph icon" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>
          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.link() }"
            @click="commands.link();"
            >
            <i class="chain icon" />
          </button>

          <button
            class="menubar__button"
            @click="commands.undo"
          >
            <i class="undo icon" />
          </button>

          <button
            class="menubar__button"
            @click="commands.redo"
          >
            <i class="redo icon" />
          </button>
        </div>
      </editor-menu-bar>
      <editor-content style="margin: 20px 0px 0px 0px;" class="editor email-content content" :editor="explanationEditor" />
      <div v-if="activeQuestion && activeQuestion.type == 'multipleChoice'">
        <label>Answers</label>
        <div v-for="ans in activeQuestion.Answers" :key="ans.id">
          {{ans}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
  import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
  } from 'tiptap-extensions'
  export default {
    name: 'questions',
    components: {
      EditorMenuBar,
      EditorContent
    },
    beforeDestroy() {
      this.editor.destroy()
      this.explanationEditor.destroy()
    },
    data () {
        let ctx = this
        return {
          search: '',
          category: '',
          questions: [],
          activeImage: '',
          activeQuestionId: '',
          editor: new Editor({
            content: '',
            onUpdate: ({getHTML}) => {
              this.content = getHTML()
              let ix = ctx.questions.findIndex(q => q.id == ctx.activeQuestion.id)
              ctx.questions[ix].Question = this.content
              ctx.$req.put(`questions/${ctx.activeQuestion.id}`, {
                Question: this.content
              }, this.$bus.headers)
                .then(r => console.log(r))
                .catch(e => console.log(e))
            },
            extensions: [
              new Blockquote(),
              new BulletList(),
              new CodeBlock(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Link(),
              new Bold(),
              new Code(),
              new Italic(),
              new Strike(),
              new Underline(),
              new History(),
            ]
          }),
          explanationEditor: new Editor({
            content: '',
            onUpdate: ({getHTML}) => {
              this.content = getHTML()
              let ix = ctx.questions.findIndex(q => q.id == ctx.activeQuestion.id)
              ctx.questions[ix].Explanation = this.content
              ctx.$req.put(`questions/${ctx.activeQuestion.id}`, {
                Explanation: this.content
              }, this.$bus.headers)
                .then(r => console.log(r))
                .catch(e => console.log(e))
            },
            extensions: [
              new Blockquote(),
              new BulletList(),
              new CodeBlock(),
              new HardBreak(),
              new Heading({ levels: [1, 2, 3] }),
              new HorizontalRule(),
              new ListItem(),
              new OrderedList(),
              new TodoItem(),
              new TodoList(),
              new Link(),
              new Bold(),
              new Code(),
              new Italic(),
              new Strike(),
              new Underline(),
              new History(),
            ]
          })
      }
    },
    computed: {
      activeQuestion: function () {
        return this.questions.find(q => q.id == this.activeQuestionId)
      },
      categories: function () {
        return this.questions
          .map(q => (q.Category || {}))
          .filter(c => c.name)
          .filter((c, i, s) => s.findIndex(cat => cat.name == c.name) == i)
      },
      filteredQuestions: function () {
        let qs = this.questions
        let category = this.categories.find(c => c.id == this.category)
        if (this.category != '') {
          qs = qs.filter(q => (q.Category || {}).name == category.name)
        }
        if (this.search != '') {
          qs = qs.filter(q => q.Question.toLowerCase().includes(this.search.toLowerCase()))
        }
        return qs
      }
    },
    mounted () {
      this.$req.get('questions?_limit=-1', this.$bus.headers)
        .then(r => {
          this.questions = r.data
        }).catch(e => console.log(e))
    },
    methods: {
      showModal: function (q) {
        this.activeQuestionId = q.id
        let question = this.questions.find(qu => qu.id == q.id)
        this.editor.setContent(question.Question)
        this.explanationEditor.setContent(question.Explanation)
        this.activeImage = (q.Image || {}).url
        /* eslint-disable-next-line */
        $('.ui.edit.modal').modal('show')
        
      },
      hideModal: function () {
        /* eslint-disable-next-line */
        $('.ui.edit.modal').modal('hide')
      }
    }
  }
</script>

<style style>
  .ProseMirror {
    outline: none;
  }
  .ProseMirror h1 {
    font-size: 2em !important;
  }
  .ProseMirror h2 {
    font-size: 1.2em !important;
  }
  .menubar {
    margin: 8px 0px -10px 0px;
  }
  

  .editor {
    padding: 12px;
    min-height: 160px;
  }
  .menubar__button {
    background: transparent;
    padding: 8px;
  }
  .menubar__button:hover {
    background: rgba(0,0,0,0.05)
  }
  .mailPopup {
    padding: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  .mailPopup label {
    margin-left: 5%;
  }
  .emails {
    width: 90%;
    margin: 8px 5%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 8px 4px;
    height: 80px;
    overflow-y: scroll;
  }
  .emails .address {
    margin: 4px 4px;
    border-radius: 2px;
    height: 28px;
    display: inline-flex;
    font-size: 12px;
  }
  .emails .address i {
    margin: auto 4px !important;
  }
  .email-content {
    width: 100%;
    margin-top: 20px;
    font-size: 12px;
    border: 1px solid #eee;
  }
  .sendEmailButton {
    margin-left: auto;
  }
  .mail-group {
    border: 1px solid #eee;
    padding: 8px;
    margin-top: 12px;
    border-radius: 4px;
    width: fit-content;
  }
</style>